


*
{
    margin: 0;
    padding: 0;
}


body
{
    overflow: hidden;
    background-image: url('pages/background.png');
    background-size: contain;
    background-position: center;
}

h1 {
    color: #FF6833;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 100;
}

h3 {
    color: #FF6833;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 100;
}

h2 {
    color: #FF6833;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 100;
}

h4 {
    color: black;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin-top: 30px;
}

a {
    color: black;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin-top: 30px;
}

p {
    color: #FF6833;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 100;
    line-height: 50px;
    text-transform: uppercase;
}


/* .construction {
    width: 100%;
    height: 100vh;
    z-index: 15;
    transform: translateZ(300px);
    background-color: #FF6833;
    position: fixed;
    padding-top: 30vh;
} */

.phoneScreen {
    width: 100%;
    height: 100vh;
    z-index: 15;
    transform: translateZ(300px);
    background-color: #FF6833;
    position: fixed;
    padding-top: 30vh;
    visibility: visible;
}


.phoneScreen.invisible {
    visibility: hidden;
}

.shop.visible {
    display: flex;
    flex-wrap: wrap;
    width: 92%;
    background-color: #FF6833;
    border: 2px solid black;
    position: fixed;
    height: 91vh;
    z-index: 3;
    overflow: scroll;
    transform: scale(1,1);
    transition: transform .3s;
    padding-top: 5%;
    padding-left: 9%;
    padding-right: 1000px;
}


.shop {
    transform: scale(0,0);
    transition: transform .3s;
}

.shopAbout.visible {
    width: 20%;
    height: max-content;
    right: 20px;
    bottom: 90px;
    z-index: 4;
    position: fixed;
    background-color: #FF6833;
    transform: scale(1,1);
    transition: transform .3s;
}

.shopAbout {
    width: 200px;
    height: max-content;
    right: 20px;
    bottom: 90px;
    z-index: 11;
    bottom: 90px;
    position: fixed;
    background-color: #FF6833;
    transform: scale(0,0);
    transition: transform .3s;
}

.scroll {
    position: absolute;
    width: 2%;
    left: 15px;
    background-color: black;
    padding-left: 7px;
    padding-top: 15px;
    padding-right: 10px;
    border-radius: 20px;
}

/* .shopBack.visible {
    position: absolute;
    z-index: 10;
    bottom: 90px;
    right: 20px;
    width: 200px;
    height: 50px;
    background-color: black;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    transform: translate(1,1);
    transition: transform .3s;
}

.shopBack {
    transform: translate(0,0);
} */



.shopAbout.visible {
    width: 300px;
    height: max-content;
    background-color: black;
    position: fixed;
    vertical-align: middle;
    padding: 10px;
    right: 20px;
    bottom: 90px;
    float: right;
    border-radius: 20px;
    visibility: visible;
    z-index: 10;
}

.shopBack {
    width: 0px;
}

.shopAbout {
    visibility: hidden;
}

.shopitem {
    height: 24%;
    margin-left: 40px;
    margin-right: 40px;
    animation: spin3 6s linear infinite;
    z-index: 9;
}

.row {
    width: 100%;
    height: 25vh;
}


.all {
    filter: blur(5rem);
    transition: filter .5s;
}

.all.unblur {
    filter: blur(0rem);
}

.openingButton {
    position: absolute;
    bottom: 220px;
    width: 250px;
    vertical-align: middle;
    height: 50px;
    border: 2.5px solid black;
    background-color: black;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    transform: scale(1,1);
    transition: transform .3s
}

.openingButton2 {
    position: absolute;
    bottom: 220px;
    width: 250px;
    vertical-align: middle;
    height: 50px;
    border: 2.5px solid black;
    background-color: black;
    border-radius: 20px;
    text-align: center;
    line-height: 50px;
    transform: scale(1,1);
}

.openingButton.blur {
    transform: scale(0,0);
}

.openingButton2.blur {
    transform: scale(0,0);
}

.opening {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 100vh;
    transform: scale(1,1)
}

.opening.invisible {
    transform: scale(0,0)
}

.titleImg {
    vertical-align: middle;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    transform: scale(1,1);
}

.titleImg.blur {
    vertical-align: middle;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    transform: scale(0,0);
}

.openingAbout {
    position: fixed;
    width: 50%;
    left: 25%;
    right: 25%;
    height: max-content;
    background-color: black;
    border-radius: 20px;
    padding: 15px;
    transform: scale(0,0);
    transition: transform .3s;
}

.openingAbout.blur {
    transform: scale(1,1);
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 1;
}

.webgl2
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.topTicker {
    position: fixed;
    display: flex;
    top: 20px;
    left: 20px;
    right: 20px;
    height: 50px;
    border: 2.5px solid black;
    background-color: black;
    border-radius: 20px;
    z-index: 4;
    padding-left: 20px;
    overflow: hidden;
}

.bottomTicker {
    position: fixed;
    display: flex;
    bottom: 20px;
    left: 20px;
    right: 20px;
    height: 50px;
    border: 2.5px solid black;
    background-color: black;
    border-radius: 20px;
    z-index: 5;
    padding-left: 20px;
    overflow: hidden;
}

.ticker1 {
    top: -20px;
    float: left;
    white-space: nowrap;
    animation: animateText 20s linear infinite;
    /* animation-delay: -20s; */
}

.ticker2 {
    position: relative;
    top: -50px;
    float: right;
    animation: animateText2 20s linear infinite;
    /* animation-delay: -40s; */
}

.tickerDivider {
    position: relative;
    width: 20px;
    top: 3px;
}

.ticker1 span {
    margin-left: 10px;
    margin-right: 10px;
}

.ticker2 span {
    margin-left: 10px;
    margin-right: 10px;
}

@keyframes animateText
{
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
    }
}

@keyframes animateText2
{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}

.ticket {
    position: fixed;
    top: 90px;
    left: 20px;
    width: 170px;
    z-index: 6;
    animation: spin 3s linear infinite;
}

.ticket2 {
    position: fixed;
    top: 90px;
    left: 20px;
    width: 170px;
    z-index: 6;
    animation: spin2 3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotateY(0deg);
        opacity: 1;
    }
    24% {
        transform: rotateY(90deg);
        opacity: 1;
    }
    25% {
        transform: rotateY(90deg);
        opacity: 0;
    }
    50% {
        transform: rotateY(180deg);
        opacity: 0;
    }
    74% {
        transform: rotateY(270deg);
        opacity: 0;
    }
    75% {
        transform: rotateY(270deg);
        opacity: 1;
    }
    100% {
        transform: rotateY(360deg);
        opacity: 1;
    }
}

@keyframes spin2 {
    0% {
        transform: rotateY(0deg);
        opacity: 0;
    }
    24% {
        transform: rotateY(90deg);
        opacity: 0;
    }
    25% {
        transform: rotateY(90deg);
        opacity: 1;
    }
    50% {
        transform: rotateY(180deg);
        opacity: 1;
    }
    74% {
        transform: rotateY(270deg);
        opacity: 1;
    }
    75% {
        transform: rotateY(270deg);
        opacity: 0;
    }
    100% {
        transform: rotateY(360deg);
        opacity: 0;
    }
}

.ticketImg {
    width: 100%;
}

.ticketImg2 {
    width: 100%;
    transform: scaleX(-1);
}

.shopButton {
    position: fixed;
    top: 90px;
    right: 20px;
    width: 300px;
    z-index: 10;
}

.shopButtonImage {
    width: 300px;
    animation: spin3 3s linear infinite;
}

@keyframes spin3 {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}


.title {
    position: relative;
    left: 20px;
    bottom: 20px;
    width: 150px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 5;
    transition: transform 0.3s;
}

.description {
    position: relative;
    left: 20px;
    width: 400px;
    height: max-content;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: left;
    line-height: 28.5px;
    z-index: 5;
    transition: transform 0.3s;
    padding: 15px;
}

/* Vacuum */


#titleVacuum {
    transform: scale(0, 0);
    width: 160px;
    z-index: 5;
}

#descriptionVacuum {
    transform: scale(0, 0);
    z-index: 5;
}

#titleVacuum.visible {
    transform: scale(1,1);
}

#descriptionVacuum.visible {
    transform: scale(1,1);
}

.pointVacuum
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointVacuum.visible .label
{
    transform: scale(1,1);
}

.pointVacuum.visible.inside .label
{
    transform: scale(0,0);
}

.pointVacuum.inside .label
{
    transform: scale(0,0);
}

.pointVacuum .label
{
    position: absolute;
    top: -30px;
    width: 160px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
    z-index: 5;
}

.backButtonVacuum {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonVacuum.visible {
    transform: scale(1, 1);
}

.lessInfoVacuum {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 2;
    transition: transform 0.3s;
    transform: scale(0, 0);
    z-index: 6;
}

.moreInfoVacuum {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 2;
    transform: scale(0, 0);
    z-index: 6;
}

.lessInfoVacuum.visible {
    transform: scale(1, 1);
}

.moreInfoVacuum.visible {
    transform: scale(1, 1);
}

/* Laundry */


#titleLaundry {
    transform: scale(0, 0);
    width: 180px;
    z-index: 5;
}

#descriptionLaundry {
    transform: scale(0, 0);
    z-index: 5;
}

#titleLaundry.visible {
    transform: scale(1,1);
}

#descriptionLaundry.visible {
    transform: scale(1,1);
}

.pointLaundry
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointLaundry.visible .label
{
    transform: scale(1,1);
}

.pointLaundry.visible.inside .label
{
    transform: scale(0,0);
}

.pointLaundry.inside .label
{
    transform: scale(0,0);
}

.pointLaundry .label
{
    position: absolute;
    top: -30px;
    width: 180px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonLaundry {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonLaundry.visible {
    transform: scale(1, 1);
}

.lessInfoLaundry {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoLaundry {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoLaundry.visible {
    transform: scale(1, 1);
}

.moreInfoLaundry.visible {
    transform: scale(1, 1);
}



/* Trashcan */

#titleTrash {
    transform: scale(0, 0);
    width: 200px;
    z-index: 5;
}

#descriptionTrash {
    transform: scale(0, 0);
    z-index: 5;
}

#titleTrash.visible {
    transform: scale(1,1);
}

#descriptionTrash.visible {
    transform: scale(1,1);
}

.pointTrash
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointTrash.visible .label
{
    transform: scale(1,1);
}

.pointTrash.visible.inside .label
{
    transform: scale(0,0);
}

.pointTrash.inside .label
{
    transform: scale(0,0);
}

.pointTrash .label
{
    position: absolute;
    top: -30px;
    width: 200px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonTrash {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonTrash.visible {
    transform: scale(1, 1);
}

.lessInfoTrash {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoTrash {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoTrash.visible {
    transform: scale(1, 1);
}

.moreInfoTrash.visible {
    transform: scale(1, 1);
}



/* Pills */

#titlePills {
    transform: scale(0, 0);
    width: 210px;
    z-index: 5;
}

#descriptionPills {
    transform: scale(0, 0);
    z-index: 5;
}

#titlePills.visible {
    transform: scale(1,1);
}

#descriptionPills.visible {
    transform: scale(1,1);
}

.pointPills
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointPills.visible .label
{
    transform: scale(1,1);
}

.pointPills.visible.inside .label
{
    transform: scale(0,0);
}

.pointPills.inside .label
{
    transform: scale(0,0);
}

.pointPills .label
{
    position: absolute;
    top: -30px;
    width: 210px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonPills {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonPills.visible {
    transform: scale(1, 1);
}

.lessInfoPills {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoPills {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoPills.visible {
    transform: scale(1, 1);
}

.moreInfoPills.visible {
    transform: scale(1, 1);
}




/* Motem */

#titleMotem {
    transform: scale(0, 0);
    width: 150px;
    z-index: 5;
}

#descriptionMotem {
    transform: scale(0, 0);
    z-index: 5;
}

#titleMotem.visible {
    transform: scale(1,1);
}

#descriptionMotem.visible {
    transform: scale(1,1);
}

.pointMotem
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointMotem.visible .label
{
    transform: scale(1,1);
}

.pointMotem.visible.inside .label
{
    transform: scale(0,0);
}

.pointMotem.inside .label
{
    transform: scale(0,0);
}

.pointMotem .label
{
    position: absolute;
    top: -30px;
    width: 150px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonMotem {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonMotem.visible {
    transform: scale(1, 1);
}

.lessInfoMotem {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoMotem {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoMotem.visible {
    transform: scale(1, 1);
}

.moreInfoMotem.visible {
    transform: scale(1, 1);
}



/* Switch */

#titleSwitch {
    transform: scale(0, 0);
    width: 150px;
    z-index: 5;
}

#descriptionSwitch {
    transform: scale(0, 0);
    z-index: 5;
}

#titleSwitch.visible {
    transform: scale(1,1);
}

#descriptionSwitch.visible {
    transform: scale(1,1);
}

.pointSwitch
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointSwitch.visible .label
{
    transform: scale(1,1);
}

.pointSwitch.visible.inside .label
{
    transform: scale(0,0);
}

.pointSwitch.inside .label
{
    transform: scale(0,0);
}

.pointSwitch .label
{
    position: absolute;
    top: -30px;
    width: 150px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonSwitch {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonSwitch.visible {
    transform: scale(1, 1);
}

.lessInfoSwitch {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoSwitch {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoSwitch.visible {
    transform: scale(1, 1);
}

.moreInfoSwitch.visible {
    transform: scale(1, 1);
}



/* Clock */

#titleClock {
    transform: scale(0, 0);
    width: 260px;
    z-index: 5;
}

#descriptionClock {
    transform: scale(0, 0);
    z-index: 5;
}

#titleClock.visible {
    transform: scale(1,1);
}

#descriptionClock.visible {
    transform: scale(1,1);
}

.pointClock
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointClock.visible .label
{
    transform: scale(1,1);
}

.pointClock.visible.inside .label
{
    transform: scale(0,0);
}

.pointClock.inside .label
{
    transform: scale(0,0);
}

.pointClock .label
{
    position: absolute;
    top: -30px;
    width: 260px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonClock {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 190px;
    height: 60px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonClock.visible {
    transform: scale(1, 1);
}


.lessInfoClock {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoClock {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoClock.visible {
    transform: scale(1, 1);
}

.moreInfoClock.visible {
    transform: scale(1, 1);
}



/* Phone */

#titlePhone {
    transform: scale(0, 0);
    width: 120px;
    z-index: 5;
}

#descriptionPhone {
    transform: scale(0, 0);
    z-index: 5;
}

#titlePhone.visible {
    transform: scale(1,1);
}

#descriptionPhone.visible {
    transform: scale(1,1);
}

.pointPhone
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointPhone.visible .label
{
    transform: scale(1,1);
}

.pointPhone.visible.inside .label
{
    transform: scale(0,0);
}

.pointPhone.inside .label
{
    transform: scale(0,0);
}

.pointPhone .label
{
    position: absolute;
    top: -30px;
    width: 120px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonPhone {
    position: fixed;
    bottom:90px;
    right: 20px;
    width: 190px;
    height: 60px;
    float: right;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonPhone.visible {
    transform: scale(1, 1);
}

.lessInfoPhone {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transition: transform 0.3s;
    transform: scale(0, 0);
}

.moreInfoPhone {
    position: fixed;
    width: 190px;
    height: 60px;
    bottom:170px;
    right: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 6;
    transform: scale(0, 0);
}

.lessInfoPhone.visible {
    transform: scale(1, 1);
}

.moreInfoPhone.visible {
    transform: scale(1, 1);
}



/* Blender */

#titleBlender {
    transform: scale(0, 0);
    width: 170px;
    z-index: 5;
}

#descriptionBlender {
    transform: scale(0, 0);
    z-index: 5;
}

#titleBlender.visible {
    transform: scale(1,1);
}

#descriptionBlender.visible {
    transform: scale(1,1);
}

.pointBlender
{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
}

.pointBlender.visible .label
{
    transform: scale(1,1);
}

.pointBlender.visible.inside .label
{
    transform: scale(0,0);
}

.pointBlender.inside .label
{
    transform: scale(0,0);
}

.pointBlender .label
{
    position: absolute;
    top: -30px;
    width: 170px;
    height: 60px;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    transition: transform 0.3s;
    cursor: help;
    background-color: black;
}

.backButtonBlender {
    position: relative;
    width: 190px;
    height: 60px;
    float: right;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    z-index: 2;
    transform: scale(0, 0);
    transition: transform 0.3s;
}

.backButtonBlender.visible {
    transform: scale(1, 1);
}

.lessInfoBlender {
    position: relative;
    width: 190px;
    height: 60px;
    bottom: 20px;
    top: 45px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transition: transform 0.3s;
    transform: scale(0, 0);
    z-index: 5;
}

.moreInfoBlender {
    position: relative;
    width: 190px;
    height: 60px;
    bottom: 20px;
    background-color: black;
    border: 2.5px solid black;
    border-radius: 20px;
    text-align: center;
    line-height: 60px;
    transform: scale(0, 0);
    z-index: 5;
}

.lessInfoBlender.visible {
    transform: scale(1, 1);
}

.moreInfoBlender.visible {
    transform: scale(1, 1);
}






.bottom {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: max-content;
    height: max-content;
    z-index: 5;
}


.info {
    position: fixed;
    bottom: 90px;
    width: max-content;
    height: max-content;
    transition: transform 0.3s;
    z-index: 4;
}









